.guest-book-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 85%;
  margin: auto;
  border-radius: 10px;
  background-color: #0d1117;
  color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(255, 215, 0, 0.3);
}

.image-box {
  width: 43%;
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comment-section {
  flex: 2;
  width: 40%;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
}

.comment-section h2 {
  font-size: 24px;
  color: gold;
  margin-bottom: 10px;
  text-align: left;
}

.form-book {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-book input,
.form-book textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  background-color: #161b22;
  color: white;
}

.form-book textarea {
  height: 80px;
  resize: none;
}

.form-book button {
  padding: 10px;
  background-color: #21262d;
  color: white;
  border: 1px solid gold;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-book button:hover {
  background-color: gold;
  color: black;
}

.messages-container {
  max-height: 185px;
  overflow-y: auto;
  border: 1px solid #444;
  padding: 10px;
  border-radius: 5px;
  background-color: #161b22;
}

.message {
  padding: 10px;
  border-bottom: 1px solid #333;
  text-align: left;
}

.message-name {
  font-weight: bold;
  color: gold;
  text-align: left;
}

.timestamp {
  font-size: 12px;
  color: #777;
  margin-left: 10px;
}

.message-text {
  color: #bebebe;
  margin-top: 5px;
  font-style: italic;
}

.no-messages {
  text-align: center;
  font-style: italic;
  color: #888;
}

@media (max-width: 768px) {
  .image-box {
    display: none;
  }

  .messages-container {
    max-height: 140px;
  }

  .comment-section {
    padding: 0;
  }

  .message-name {
    font-size: 14px;
  }

  .timestamp {
    font-size: 11.5px;
  }

  .message-text {
    font-size: 12px;
  }
}
