.book-container {
  margin-top: 40px;
}

.book-title {
  font-size: 4rem;
  margin-bottom: 40px;
}

.book {
  color: #cbacf9;
}

@media (max-width: 768px) {
  .book-title {
    font-size: 2.5rem;
  }
}
